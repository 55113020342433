/**
 * # i18n Platform service
 *
 * > @see /apps/shared/doc/i18n.md
 *
 * This one is ONLY for Platform (menus etc)
 *
 * It will translate few sentences so use simple imnplementation (does not use big library)
 */
import type { Signal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { filter, map, ReplaySubject, take } from 'rxjs';

import { flattenJSON, isArray } from '@evc/web-components';

import type { AbstractI18nService } from '../../types/i18n-service.type';

async function importLanguageFile(language:string) {
  return (() => {
    switch (language) {
      case 'fr':
        return import('../../../assets/i18n/fr.json');
      default:
        return import('../../../assets/i18n/en.json');
    }
  })()
  .then(module => module.default);
}

interface LangChangeEvent {
  lang: string;
  translations: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class I18nPlatformService implements AbstractI18nService {
  #destroyRef = inject(DestroyRef);

  // messages are flatten for easy get : `{ 'menus.applications.links.home': 'Accueil', ... }`
  #messages = signal<Record<string, string>>({});
  #lang = signal<string|undefined>(undefined);

  onLangChange$ = new ReplaySubject<LangChangeEvent>();
  onReady$: Observable<LangChangeEvent> = this.onLangChange$
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((event:LangChangeEvent) => event && event.lang === this.lang),
      take(1),
    );

  get lang():string {
    return this.#lang()!;
  }

  setLanguage(language:string):void {
    this.#lang.set(language);

    importLanguageFile(language)
      .then(messages => {
        const translations = flattenJSON(messages);

        this.#messages.set(translations);
        this.onLangChange$.next({ lang: language, translations });
      });
  }

  t(key: string|Array<string>):string {
    const path = this.#flattenKey(key);

    return this.#messages()[path];
  }

  t$(key: string|Array<string>):Observable<string> {
    return this.onLangChange$.pipe(
      takeUntilDestroyed(this.#destroyRef),
      map(() => this.t(key)),
    );
  }

  tComputed(key: string|Array<string>):Signal<string> {
    const path = this.#flattenKey(key);

    return computed(() => this.#messages()[path]);
  }

  #flattenKey(key: string|Array<string>):string {
    return isArray(key) ? key.join('.') : key;
  }
}
