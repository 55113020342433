/**
 * Shared (app) Config Service
 * ============================
 *
 * Both libs have there own config service ; this one is for our Apps
 *
 * - import environment config the same way in any apps
 * - share some config between apps (i18n...)
 * - and already accept extra configs
 *
 * The idea is to use this one (generic) in any apps, by injecting our app config type.
 * Exept if bug app with lot of specific config management, you may use a specific config service
 *
 * - merge .env with default
 * - then merge config with default, env and platformConfig
 * - then compute extra config clean (i18n)
 *
 * > This is shared only in our connect platform monoorepo
 * >
 * > - it is not for exxternal applications.
 * > - and we assume it will be used for ~~custom~~Greenfield mode.
 */

import { Inject, Injectable, Optional } from '@angular/core';

import { PlatformConfigService } from '@evc/platform';
import { ConfigService, deepMerge, ENV_VARIABLES } from '@evc/web-components';

import { defaultSharedConfigFactory } from './config.default';
import type { SharedConfig, SharedEnv } from './config.type';

@Injectable({
  providedIn: 'root',
})
export class SharedConfigService<
  T extends SharedEnv = SharedEnv,
  U extends SharedConfig = SharedConfig
> extends ConfigService<U> {
  constructor(
    @Optional() @Inject(ENV_VARIABLES) readonly envVars: T,
    private readonly platformConfig: PlatformConfigService,
  ) {
    const environment = envVars ?? { ENV: 'production' };
    const config = deepMerge<U>(
      platformConfig.config(),
      defaultSharedConfigFactory(environment),
    );

    super(config, environment);
  }
}
