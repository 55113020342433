import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'evc-loading-spinner',
  templateUrl: './loading-spinner.component.svg',
  styleUrl: './loading-spinner.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {}
