import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import type { Observable } from 'rxjs';

import { OptionalAsyncPipe } from '../../pipes/OptionalAsync.pipe';

@Component({
  selector: 'evc-tooltip',
  standalone: true,
  imports: [OptionalAsyncPipe],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @HostBinding('style.left.px') public left = 0;
  @HostBinding('style.top.px') public top = 0;
  public tooltip: string | Observable<string> = '';
}
