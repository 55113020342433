import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { IconListType } from '../../types/icon-list.type';
import { Maybe } from '../../utils/types';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'evc-tag',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent {
  @Input() rightIcon: Maybe<IconListType> = undefined;
  @Input() leftIcon: Maybe<IconListType> = undefined;
  @Input() iconSize: 'logo' | 'lg' | 'md' | 'sm' | 'xs' | 'free' = 'xs';
  @Input() text = '';
}
