<div class="evc-cell" [ngClass]="{'evc-cell--clickable' : cellData.click ?? false}">

  @if (cellData.avatar) {
    <evc-avatar [avatar]="cellData.avatar"></evc-avatar>
  }

  @if (cellData.iconLeft) {
    @if (cellData.iconLeft.icon){
      <div class="evc-cell__icon-container">
        <evc-svg-icon size="sm" [icon]="cellData.iconLeft.icon"></evc-svg-icon>
      </div>
    }
    @else if (cellData.iconLeft.svgPath){
      <div  class="evc-cell__icon-badge">
        <evc-svg-icon size="sm" [svgPath]="cellData.iconLeft.svgPath"></evc-svg-icon>
      </div>
    }
  }

  @if (cellData.value.leadText || cellData.value.mainText ||cellData.value.subText) {
    <div class="evc-cell__text-container">
      @for (key of cellDataKeys; track key) {
        @if (cellData.value[key]) {
          <span
            class="evc-cell__text--{{key}} evc-cell--{{cellData.style || 'normal'}}"
            [ngClass]="{ 'evc-cell--disabled': cellData.disabled ?? false }"
          >
            {{ cellData.value[key] || '--' }}
          </span>
        }
      }
    </div>
  }

  @if (cellData.value.tag) {
    <evc-tag [text]="cellData.value.tag.text" [leftIcon]="cellData.value.tag.leftIcon"></evc-tag>
  }

  @if (cellData.iconRight) {
    @if (cellData.iconRight.icon){
      <div class="evc-cell__icon-container">
        <evc-svg-icon size="sm" [icon]="cellData.iconRight.icon"></evc-svg-icon>
      </div>
    }
    @else if (cellData.iconRight.svgPath){
      <div  class="evc-cell__icon-badge">
        <evc-svg-icon size="sm" [svgPath]="cellData.iconRight.svgPath"></evc-svg-icon>
      </div>
    }
  }
  @if (cellData.statusInfo) {
    <div class="evc-cell__badge">
      <evc-status-indicator
        [text]="cellData.statusInfo.text"
        [icon]="cellData.statusInfo.icon"
        [status]="cellData.statusInfo.status || 'neutral'"
        [upperCase]="cellData.statusInfo.upperCase || false"
      ></evc-status-indicator>
    </div>
  }
</div>
