import type { ApiOrganizationFetchResponse, Organization } from './organizations.type';

export const MOCK_ORGANIZATIONS:Organization[] = [{
  id: '1',
  name: 'Organization 1',
  current: false,
  avatar: {
    type: 'initials',
    color: 'red',
    light: true,
    initials: 'BA',
  },
  roles: [],
}, {
  id: '2',
  name: 'An other Organization',
  current: true,
  avatar: {
    type: 'image',
    src: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Test-Logo.svg',
  },
  roles: [],
}];

export const MOCK_API_ORG_PAYLOAD: ApiOrganizationFetchResponse[] = [
  {
    organizationId: '1',
    organizationName: 'Organization 1',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Test-Logo.svg',
  },
  {
    organizationId: '2',
    organizationName: 'An other Organization',
  },
];
