import type { EnvProvider } from '@evc/web-components';
import { ENV_VARIABLES } from '@evc/web-components';

import type { PlatformEnv } from '../providers/env.type';
import { ENVIRONMENT } from '../providers/env.type';

/** ensure some default values for our environment config */
function environmentFactory(partialEnv:Partial<PlatformEnv>):PlatformEnv {
  const environment:PlatformEnv = {
    ENV: ENVIRONMENT.production,
    LOCALES: ['en', 'fr'],
    LOCALES_STORAGE_KEY: 'evc_user_lang',
    ...partialEnv,
  } as PlatformEnv;

  // ensure GREENFIELD is false if no min requirements
  environment.GREENFIELD = (() => {
    const { AUTH_CLIENT_ID, GREENFIELD } = environment;
    const isTrully = GREENFIELD === true || GREENFIELD as unknown === 'true';
    const haveAuthClientId = (AUTH_CLIENT_ID && AUTH_CLIENT_ID !== '') || false;

    return (isTrully && haveAuthClientId) || false;
  })();

  if (environment.GREENFIELD) {
    if (!environment.API_URI) {
      const uris = {
        local: 'https://core.api.ca.connect.evcnt.local/', // /swagger/index.html to test
        testing: 'https://core.api.ca.connect.evcnt.local/',
        development: 'https://core.api.ca.connect.dev-evcnt.com/',
        staging: 'https://core.api.ca.connect.stg-evcnt.com/',
        production: 'https://core.api.ca.connect.tnmconnect.com/',
      } as Record<string, string>;
      environment.API_URI = uris[environment.ENV!] ?? uris.production;
    }
  }

  return environment as PlatformEnv;
}

/** please use this one if using the platform lib
 * - use the one from web-components only if using the web-components lib standalone
 */
export function provideEnv(environment:PlatformEnv):EnvProvider {
  return {
    provide: ENV_VARIABLES,
    useFactory: () => environmentFactory(environment),
  };
}
