@if (showLoader()) {
  <div
    class="loader"
    data-testid="loader"
    [attr.data-loaded]="loaded()"
  >
  @if (showSpinner()) {
    <evc-loading-spinner class="spinner"/>
  }
  </div>
}