import type { AfterViewInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { NavButtonComponent } from '../nav-button/nav-button.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'evc-modal',
  standalone: true,
  imports: [SvgIconComponent, NavButtonComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit {
  @Output() public readonly reduceModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly closeModal: EventEmitter<void> = new EventEmitter<void>();

  @Input() public showBackdrop = true;
  @Input() public showReduceButton = true;
  @Input() public showCloseButton = true;
  @Input() public width = 'auto';
  @Input() public height = 'auto';
  @Input() public minWidth = 'auto';
  @Input() public minHeight = 'auto';
  @Input() public maxWidth = 'auto';
  @Input() public maxHeight = 'auto';

  @ViewChild('modalContainer') public modalContainer?: ElementRef;
  @ViewChild('modalBackdrop') public modalBackdrop?: ElementRef;

  constructor(private _renderer: Renderer2) {}

  @HostListener('document:keydown.escape')
  public onCloseModal(): void {
    this.closeModal.emit();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.showBackdrop) {
        this._renderer.addClass(this.modalBackdrop?.nativeElement, 'show');
      }
      this._renderer.addClass(this.modalContainer?.nativeElement, 'show');
    });
  }
}
