import type { OnInit, Signal, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';

import type { AppIdentity, Organization, UserConfig } from '@evc/platform';
import { MOCK_USER_CONFIG, OrganizationsService, PlatformComponent, PlatformService } from '@evc/platform';
import type { Maybe, Menu } from '@evc/web-components';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService } from '@shared/services/i18n/i18n.service';

import { CurrentOrgCardComponent } from './components/current-org-card/current-org-card.component';
import { NavConfigService } from './services/nav-config/nav-config.service';

@Component({
  standalone: true,
  imports: [RouterModule, PlatformComponent, CurrentOrgCardComponent],
  selector: 'evc-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  leftbarConfig: WritableSignal<Menu[]>;
  appIdentification: WritableSignal<AppIdentity>;
  i18nService = inject(I18nService);
  currentOrg: Signal<Maybe<Organization>> = computed(() => this.#orgService.current());

  readonly #navConfigService = inject(NavConfigService);
  readonly #config = inject(SharedConfigService);
  readonly #orgService = inject(OrganizationsService);
  readonly #platformService = inject(PlatformService);

  #mockUser = signal<Maybe<UserConfig>>(undefined);
  get mockUser(): Signal<Maybe<UserConfig>> {
    return this.#mockUser.asReadonly();
  }
  get ready():Signal<boolean> {
    return this.#platformService.ready;
  }

  constructor() {
    this.leftbarConfig = this.#navConfigService.leftbarConfig;
    this.appIdentification = this.#navConfigService.appIdentity;
  }

  ngOnInit(): void {
    if (this.#config.get('DEBUG_DISABLE_AUTH')) {
      this.#mockUser.set(MOCK_USER_CONFIG);
      console.error('DEBUG_DISABLE_AUTH is enabled. Mock user is set.');
    }
  }
}
