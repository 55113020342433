<div class="evc-input" [attr.data-testid]="'field-'+(id || name)">
  @if(label){
    <label [for]="id || name" class="evc-input__label">{{ label }}</label>
  }
  @if (formElementType === 'select') {
    <div class="evc-input__select">
      <select
        #input
        class="evc-input__input evc-input__input--{{ size }}"
        [class.evc-input__input--error]="hasErrors()"
        [id]="id || name"
        [attr.data-testid]="'field-input-'+(id || name)"
        [disabled]="disabled"
        [attr.name]="name"
        [attr.placeholder]="placeholder ?? null"
        (blur)="onBlur()"
        [attr.data-selected]="!!value"
        [attr.data-empty]="(options ?? []).length < 1"
        required
        [(ngModel)]="value"
        (ngModelChange)="onInputChanged($event)"
        >
        <option class="placeholder" [label]="placeholder"></option><!-- default empty value -->
        @for(option of options; track index; let index=$index) {
          <option [value]="option.value">{{ option.label }}</option>
        }
      </select>
      <evc-svg-icon [icon]="isSelectOpen ? 'angle-up' : 'angle-down'" size="xs"></evc-svg-icon>
    </div>
  }@else{
    <input
      #input
      class="evc-input__input evc-input__input--{{ size }}"
      [class.evc-input__input--error]="hasErrors()"
      [attr.id]="id || name"
      [attr.data-testid]="'field-input-'+(id || name)"
      [type]="type"
      [readOnly]="readOnly"
      [disabled]="disabled"
      [maxlength]="maxLength"
      [attr.name]="name"
      [attr.placeholder]="placeholder ?? null"
      [(ngModel)]="value"
      (ngModelChange)="onInputChanged($event)"
      (blur)="onBlur()"
      />
  }
  @if (hasErrors() || (showError && errorText !== '')) {
    <div class="evc-input__errors">
      @for(error of controlDir?.errors | keyvalue ; track error.key){
        <p class="evc-input__errors-text">{{ error.value.message }}</p>
      }
      @if (errorText !== '') {
        <p class="evc-input__errors-text">{{ errorText }}</p>
      }
    </div>
  }
</div>