import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconListType } from '../../types/icon-list.type';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

export type StatusIndicatorType = {
  text: string;
  icon?: IconListType;
  status?: 'error' | 'warn' | 'success' | 'info';
  upperCase?: boolean;
}

@Component({
  selector: 'evc-status-indicator',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  @Input() public text = '';
  @Input() public icon?: IconListType;
  @Input() public status: 'error' | 'warn' | 'success' | 'info' | 'neutral' = 'info';
  @Input() public upperCase = true;
}
