@if(avatarInitials; as avatar) {
  <div class="badge badge--{{size}}" [style.background-color]="color ?? avatar.color">
    <span class="badge__text">{{ avatar.initials }}</span>
  </div>
}
@if(avatarImage; as avatar) {
  <div class="badge badge--img badge--{{size}}">
    <img [src]="avatar.src" alt="avatar" class="badge__image"/>
  </div>
}

