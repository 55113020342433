import type { Signal, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';

import type { UserProfile } from '@evc/platform';
import { OrganizationsService, SearchComponent } from '@evc/platform';
import type { Maybe, Menu } from '@evc/web-components';
import {
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  FrameComponent,
  InputComponent,
  NavButtonComponent,
  RightPanelComponent,
  SvgIconComponent,
} from '@evc/web-components';
import { TableComponent } from '@shared/components/table/table.component';
import type { RowData, TableCell, TableRow, TableRowArray } from '@shared/components/table/table.type';
import type { TabletradMapping } from '@shared/services/i18n/i18n.service';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';
import { UserCardComponent } from '@app/components/user-card/user-card.component';
import { UserDetailsComponent } from '@app/components/user-details/user-details.component';

@Component({
  standalone: true,
  selector: 'adm-users',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FrameComponent,
    TranslatePipe,
    NavButtonComponent,
    RightPanelComponent,
    SvgIconComponent,
    UserCardComponent,
    UserDetailsComponent,
    TableComponent,
    InputComponent,
    SearchComponent,
    DropdownMenuComponent,
    CloseOnClickOutsideDirective,
],
})
export class PageUsersComponent {
  #i18nService = inject(I18nService);
  readonly #orgService = inject(OrganizationsService);

  isRightPanelOpen = signal(false);
  currentOrganization = computed(() => this.#orgService.current());
  currentOrgUsers: WritableSignal<Maybe<UserProfile[]>> = signal(undefined);
  clickedUser: WritableSignal<Maybe<UserProfile>> = signal(undefined);

  appTableColumns = signal<string[]>([]);
  columnMapping = signal<{ [key: string]: string }>({});
  tableData: WritableSignal<TableRowArray> = signal([{
    name: { value: { leadText: '--' } },
    role: { value: { leadText: '--' } },
  }]);
  showUserActions = signal(false);
  userActionsMenu = signal({
    items: [
      {
        key: 'change-role',
        text: this.#i18nService.t('PAGE_TEAM.user_actions.change_role'),
        icon: 'user',
        action: {
          type: 'interactive',
          callbackOrUrl: () => console.log('change role user'),
        },
      },
      {
        key: 'remove-user',
        text: this.#i18nService.t('PAGE_TEAM.user_actions.remove_user'),
        icon: 'trash-can',
        isWarn: true,
        action:
        {
          type: 'interactive',
          callbackOrUrl: () => console.log('remove user'),
        },
      },
    ],
  } as Menu);

  // TODO : logic for this
  isUserInOrganization = signal(true);
  isUserInvited = signal(true);
  roleOptions = [
    {
      value: 'owner',
      label: this.#i18nService.t('PAGE_TEAM.role.owner'),
    },
    {
      value: 'admin',
      label: this.#i18nService.t('PAGE_TEAM.role.admin'),
    },
  ];

  constructor() {
    this.#getCurrentOrgUsers();

    const keys = ['name', 'role'];
    const tabletradMapping: TabletradMapping = this.#i18nService.createTableColumnsMapping(keys, 'PAGE_TEAM.table');
    this.appTableColumns.set(tabletradMapping.translatedKeys);
    this.columnMapping.set(tabletradMapping.columnsMapping);
  }

  sortingDataAccessor(item: TableRow, property: string): string {
    const key = property.toLocaleLowerCase();

    return (item[key] as TableCell).value.leadText ?? '';
  }

  toggleRightPanel(row: Maybe<TableRow> = undefined): void {
    let newUser:Maybe<UserProfile>;
    if (row) {
      newUser = this.currentOrgUsers()?.find((user) => user.id === (row.userData as RowData).id);
    }
    this.clickedUser?.set(newUser);

    this.isRightPanelOpen.set(!this.isRightPanelOpen());
  }

  toggleUserActions(): void {
    this.showUserActions.set(!this.showUserActions());
  }

  resendInvitation(): void {
    console.log('Resend Invitation');
  }

  #getCurrentOrgUsers(): void {
    this.#orgService.getUserList().then((users) => {
      this.currentOrgUsers.set(users);
      this.tableData.set(this.#computeCurrentOrgUsers(this.currentOrgUsers));
    }).catch(async (errors = []) => {
      console.error(errors);
    });
  }

  #computeCurrentOrgUsers(users: Signal<Maybe<UserProfile[]>>) : TableRowArray {
    const usersArray = users();
    if (!usersArray) {
      return [{
        name: {
          value: {
            leadText: this.#i18nService.t('labels.no_users_found'),
          },
        },
        role: {
          value: {
            leadText: this.#i18nService.t('labels.no_users_found'),
          },
        },
      }];
    }

    return usersArray.map((user) => ({
      name: {
        value:
          {
            leadText: user.displayName,
            mainText: user.email ?? '',
          },
        avatar: user.avatar,
      },
      role: {
        value:
        {
          tag:
          {
            text: user.roles[0],
            leftIcon: 'users',
          },
        },
      } as TableCell,
      userData: { id: user.id },
    }));
  }
}
