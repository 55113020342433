import type { OnChanges, OnInit, Signal, SimpleChange, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, EventEmitter, inject, Input, Optional, Output, untracked } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Maybe, Menu, MenuItemType, ThemeType } from '@evc/web-components';
import { AvailableThemes, ThemeService, WebComponentsConfigService } from '@evc/web-components';

import { LeftbarComponent } from './components/leftbar/leftbar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AuthService } from './core-client/auth/auth.service';
import { OrganizationsService } from './core-client/organizations/organizations.service';
import type { Organization } from './core-client/organizations/organizations.type';
import { UserService } from './core-client/user/user.service';
import type { UserApi, UserApiPossibilities } from './core-client/user/user.type';
import { PlatformService } from './platform.service';
import { PlatformConfigService } from './services/config/config.service';
import { ApplicationURIs } from './services/config/config.type';
import { LanguageService } from './services/language/language.service';
import { SearchService } from './services/search/search.service';
import { SearchFn } from './services/search/search.type';
import { AppIdentity } from './types/app-identity.type';
import type { AbstractI18nService } from './types/i18n-service.type';
import type { ApplicationType } from './types/user-menu.type';

@Component({
  selector: 'evc-platform',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LeftbarComponent, TopbarComponent, LoaderComponent],
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
})
export class PlatformComponent implements OnChanges, OnInit {
  readonly #platformService = inject(PlatformService);
  readonly #platformConfigService = inject(PlatformConfigService);
  readonly #webComponentConfigService = inject(WebComponentsConfigService);
  readonly #themeService = inject(ThemeService);
  readonly #searchService = inject(SearchService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #organizationsService = inject(OrganizationsService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #languageService = inject(LanguageService);

  @Output() readonly ready: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly login: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly selectOrganization: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly isLeftbarOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() inputAppIdentification?: AppIdentity;
  @Input() inputLinks?: ApplicationURIs;
  @Input() inputHelpMenu?: MenuItemType[];
  @Input() inputSettingsMenu?: MenuItemType[];
  @Input() inputTopTabs?: MenuItemType[];
  @Input() inputLeftBar?: Menu[];

  @Input() displayApplicationsIcon = true;
  @Input() inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined;
  @Input() inputSearch?: SearchFn;
  @Input() basePath = this.#webComponentConfigService.get('ASSETS_BASE_PATH');

  /** we cannot import shared so need to provide via input */
  @Optional() @Input() i18nAppService:{
    lang:string,
    init:()=>void,
    setUserLanguage?:(lang?:string) => void,
    onReady$?:Observable<{
      lang:string;
      translations:unknown
    }>
  } | AbstractI18nService | undefined;

  @Input() set inputUserProfile(user: UserApi|undefined) {
    if (user) {
      if (this.#platformConfigService.greenfield) throw new Error('inputUserProfile is not available in greenfield mode');

      const { applications } = user as UserApiPossibilities;
      if (applications) {
        this.inputUserApplications = applications;
      }
      const { organizations } = user as UserApiPossibilities;
      if (organizations) {
        this.inputUserOrganizations = organizations as Organization[];
      }
    }

    this.#userService.setUser(user);
  }

  @Input() public set inputUserOrganizations(organizations: Organization[]) {
    if (!organizations?.length) {
      return;
    }
    this.#organizationsService.setEntries(organizations);
  }

  @Input() set inputUserApplications(apps: ApplicationType[]) {
    this.#platformConfigService.set('applications', apps);
  }

  get leftbarMenus(): Signal<Menu[]|undefined> {
    return computed(() => {
      const { leftbar } = this.#platformConfigService.getComputed('menus')();
      if (leftbar.length <= 0) return undefined;

      return leftbar;
    });
  }

  #emitOnReady = effect(() => {
    if (!this.#platformService.ready()) return;
    untracked(() => {
      this.ready.emit();
      this.#emitOnReady.destroy();
    });
  });

  constructor() {
    this.#emitSelectOrganizationOnChange();
  }

  async ngOnInit(): Promise<void> {
    this.#platformService.init(this.i18nAppService as Maybe<AbstractI18nService>);

    this.#destroyRef.onDestroy(() => {
      this.#emitOnReady.destroy();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changed = (change:SimpleChange|undefined, key='currentValue') => {
      if (!change) return false;

      return Object.prototype.hasOwnProperty.call(change, key);
    };

    if (changed(changes.inputForcedTheme)) {
      const inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined = changes.inputForcedTheme.currentValue;
      this.#changeTheme(inputForcedTheme);
    }

    if (changed(changes.inputSearch)) {
      this.#searchService.setSearch(changes.inputSearch.currentValue);
    }

    if (changed(changes.inputAppIdentification)) {
      const inputIdentification: AppIdentity = changes.inputAppIdentification.currentValue;
      this.#platformConfigService.merge('app', inputIdentification);
    }

    if (changed(changes.inputLinks)) {
      const uris:ApplicationURIs = changes.inputLinks.currentValue;
      this.#platformConfigService.merge('uris', uris);
    }

    if (changed(changes.inputHelpMenu)) {
      const items: MenuItemType[] = changes.inputHelpMenu.currentValue;
      this.#platformConfigService.updateMenu('help', items);
    }

    if (changed(changes.inputSettingsMenu)) {
      const items: MenuItemType[] = changes.inputSettingsMenu.currentValue;
      this.#platformConfigService.updateMenu('settings', items);
    }

    if (changed(changes.inputTopTabs)) {
      const inputTopTabs: MenuItemType[] = changes.inputTopTabs.currentValue;
      this.#platformConfigService.merge('topTabs', inputTopTabs);
    }

    if (changed(changes.inputLeftBar)) {
      const inputLeftBar: Menu[] = changes.inputLeftBar.currentValue;
      this.#platformConfigService.merge('menus', {
        leftbar: inputLeftBar,
      });
    }

    if (changed(changes.basePath)) {
      const basePath:string = changes.basePath.currentValue;
      this.#webComponentConfigService.set('ASSETS_BASE_PATH', basePath);
    }
  }

  onLogin(): void {
    if (this.#platformConfigService.greenfield) {
      this.#authService.login();
    } else {
      this.login.emit();
    }
  }

  onLogout(): void {
    if (this.#platformConfigService.greenfield) {
      this.#authService.logout();
    } else {
      this.logout.emit();
    }
  }

  onToggleLeftBar(isOpen: boolean): void {
    this.isLeftbarOpenChange.emit(isOpen);
  }

  #emitSelectOrganizationOnChange(): void {
    // ignore 1st call because it's the initial value - not a change
    // could be improved using https://stackoverflow.com/questions/76200595/possible-to-access-the-old-value-in-angular-16s-effect-function-similar-to-v
    let first = true;
    effect(() => {
      const orgId = this.#organizationsService.currentOrgId();
      untracked(() => {
        if (first) {
          first = false;

          return;
        }
        this.selectOrganization.emit(orgId);
      });
    });
  }

  #changeTheme(inputForcedTheme: Exclude<ThemeType, 'auto'> | undefined): void {
    if (!inputForcedTheme) return;

    if (!AvailableThemes.includes(inputForcedTheme)) {
      throw new Error(`[platform] Given theme ${inputForcedTheme} must be one of ${AvailableThemes.join(',')}`);
    }

    this.#platformConfigService.set('theme', inputForcedTheme);
    this.#themeService.theme = inputForcedTheme;
  }
}
