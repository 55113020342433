import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Observable } from 'rxjs';

import { OptionalAsyncPipe } from '../../pipes/OptionalAsync.pipe';

@Component({
  selector: 'evc-copyright',
  standalone: true,
  imports: [OptionalAsyncPipe],
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyrightComponent {
  @Input() public copyRightText: Observable<string> | string = '© Evident Scientific';

  public year: string = new Date().getFullYear().toString();
}
