import { ChangeDetectionStrategy, Component, computed, Input } from '@angular/core';

import { UserProfile } from '@evc/platform';
import { SvgIconComponent } from '@evc/web-components';
import { TranslatePipe } from '@shared/services/i18n/i18n.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'adm-user-details',
  standalone: true,
  imports: [TranslatePipe, SvgIconComponent],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent {
  @Input() user!: UserProfile;

  formatedAddress = computed(() => {
    const { address } = this.user;
    if (!address) return undefined;
    const { street, city, province, postalCode, country } = address;

    return `
    ${ street },
    ${ city } (${ province }),
    ${ postalCode },
    ${ country }
  `;
  });
}
