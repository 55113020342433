import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { NavButtonComponent } from '../nav-button/nav-button.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'evc-right-panel',
  standalone: true,
  imports: [NavButtonComponent, SvgIconComponent],
  templateUrl: './right-panel.component.html',
  styleUrl: './right-panel.component.scss',
})
export class RightPanelComponent {
  @Output() readonly panelClosed: EventEmitter<void> = new EventEmitter<void>();

  closePanel(): void {
    this.panelClosed.emit();
  }
}
