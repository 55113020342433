/**
 * Close some element when clicked outside of it.
 * - ensure not clicked in some child elements
 */
import type { AfterViewInit } from '@angular/core';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Directive({
  selector: '[evcCloseOnClickOutside]',
  standalone: true,
})
export class CloseOnClickOutsideDirective implements AfterViewInit {
  @Output() public readonly clickedOutside: EventEmitter<void> = new EventEmitter<void>();

  public enabled = false;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _router: Router,
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (!this.enabled) {
      return;
    }

    const clickedInside: boolean = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickedOutside.emit();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.enabled = true;
    }, 0);

    this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.clickedOutside.emit();
    });
  }
}
