<div class="user-details">
  <span class="user-details__title">{{ 'PAGE_TEAM.right-panel.profile.title' | translate }}</span>
  <div class="user-details__section">
    <evc-svg-icon icon="at" size="sm" class="user-details__icon"></evc-svg-icon>
    <div class="user-details__section-infos">
      <span class="user-details__section-title">{{ 'PAGE_TEAM.right-panel.profile.email' | translate}}</span>
      <span class="user-details__section-value">{{ user.email }}</span>
    </div>
  </div>

  <div class="user-details__divider"></div>

  <div class="user-details__section">
    <evc-svg-icon icon="phone" size="sm" class="user-details__icon"></evc-svg-icon>
    <div class="user-details__section-infos">
      <span class="user-details__section-title">{{ 'PAGE_TEAM.right-panel.profile.phone' | translate}}</span>
      <span class="user-details__section-value">{{ user.phone }}</span>
    </div>
  </div>

  @if (user.address) {
    <div class="user-details__divider"></div>

    <div class="user-details__section">
      <evc-svg-icon icon="location-dot" size="sm" class="user-details__icon"></evc-svg-icon>
      <div class="user-details__section-infos">
          <span class="user-details__section-title">{{ 'PAGE_TEAM.right-panel.profile.address' | translate}}</span>
          <span class="user-details__section-value"
          >
            {{ formatedAddress() ?? 'labels.no_address_found' | translate }}
          </span>
      </div>
    </div>
  }

</div>
