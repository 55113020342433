<button
  class="evc-btn evc-btn--{{ type }} evc-btn--{{ size }} evc-btn--{{ variant }}"
  [ngClass]="{
    'evc-btn--disabled': disabled,
    'evc-btn--selected': selected
  }"
  [disabled]="disabled"
  (click)="handleCtaClick($event)"
  (mousemove)="onMouseMove($event)"
>
  @if(leftIcon){
    <evc-svg-icon [size]="getIconSize()" [icon]="leftIcon" />
  }
  <ng-content></ng-content>
  @if(rightIcon){
    <evc-svg-icon [size]="getIconSize()" [icon]="rightIcon" />
  }
</button>
