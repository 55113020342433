import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { Avatar, ImageAvatar, InitialsAvatar } from '../../types/avatar.type';

const DEFAULT_AVATAR: Avatar = {
  type: 'initials',
  initials: '',
  light: true,
  color: 'var(--badge-border)',
};

@Component({
  standalone: true,
  selector: 'evc-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() public size: 'sm' | 'md' | 'lg' = 'md';
  @Input() public color: string | undefined = undefined;
  @Input({ required: true }) public set avatar(value: Avatar | undefined) {
    this._avatar = value || DEFAULT_AVATAR;
  }

  private _avatar: Avatar = DEFAULT_AVATAR;

  public get avatarInitials(): InitialsAvatar | undefined {
    if (this._avatar.type === 'initials') {
      return this._avatar;
    }

    return undefined;
  }

  public get avatarImage(): ImageAvatar | undefined {
    if (this._avatar.type === 'image') {
      return this._avatar;
    }

    return undefined;
  }
}
