<div class="linked-orgs">
  <evc-frame>
    <div frame-title class="evc-app-title">
      <span class="evc-page-title">{{'PAGE_LINKED_ORGS.title' | translate }}</span>
    </div>

    <div frame-content class="evc-page-container">
      <p>This is LINKED ORGS PAGE</p>
    </div>
  </evc-frame>
</div>


