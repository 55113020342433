/**
 * # i18n service
 *
 * > @see /apps/shared/doc/i18n.md
 *
 * Today use @ngx-translate which is deprecated
 * So we may change lib in near furture
 *
 * That's why we create a service to wrap it - so if we swap engine this interface will remain the same
 */
import type { Signal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, Observable, take } from 'rxjs';

import type { I18nConfig } from '@evc/platform';

import { SharedConfigService } from '../config/config.service';
import type { LangChangeEvent } from './i18n.module';
import { TranslateService } from './i18n.module';

/** 🤯 @ngx-translate does not provide a standalone pipe so must export the module
 * Besause we expect to swap i18n engine, we desguise this issue to be compliant with future implementation
 * Also, because this is a facade - importing any dependency from here will remove dependencies accross all codebase
 */
export { TranslatePipe, TranslateLoader, TranslateHttpLoader } from './i18n.module';

export type TabletradMapping = {
  translatedKeys: string[];
  columnsMapping: { [key: string]: string };
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  translateService = inject(TranslateService);
  #destroyRef = inject(DestroyRef);
  #config:I18nConfig = inject(SharedConfigService).get('i18n');

  onLangChange$ = new Observable<LangChangeEvent>(observer => {
    this.translateService.onLangChange
      .subscribe(event => observer.next(event));
  });
  langReady = toSignal(this.onLangChange$);
  onReady$ = this.onLangChange$
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((event:LangChangeEvent) => event
        && event.lang === this.lang),
      take(1),
    );

  get lang():string {
    return this.translateService.currentLang;
  }

  setLanguage(language:string):void {
    this.translateService.use(language);
  }

  /** instant translate (not reactive) */
  t(key: string|Array<string>, interpolateParams?: Record<string, unknown>):string {
    return this.translateService.instant(key, interpolateParams);
  }
  /** translate using Observable (reactive) */
  t$(key: string|Array<string>, interpolateParams?: Record<string, unknown>):Observable<string> {
    return this.translateService.stream(key, interpolateParams);
  }

  ts(key: string|Array<string>, interpolateParams?: Record<string, unknown>):Signal<string> {
    return computed(() => {
      this.langReady();

      return this.translateService.instant(key, interpolateParams) as string;
    });
  }

  constructor() {
    const { languages, lang } = this.#config;

    this.translateService.addLangs(languages);
    if (lang) this.translateService.setDefaultLang(lang);
  }

  createTableColumnsMapping(columnsKeys: string[], tradScope: string): TabletradMapping {
    const translatedKeys = columnsKeys.map(key => this.t(`${tradScope}.${key}`) ?? key);

    const columnsMapping = columnsKeys.reduce((acc, key, index) => {
      acc[translatedKeys[index]] = key;

      return acc;
    }, {} as { [key: string]: string });

    return { translatedKeys, columnsMapping };
  }
}
