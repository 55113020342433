import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { OptionalAsyncPipe } from '../../pipes/OptionalAsync.pipe';
import { ActionService } from '../../services/action/action.service';
import { MenuItemType } from '../../types/menu-item.type';
import { BadgeItemType } from '../../types/organization.type';
import { AvatarComponent } from '../avatar/avatar.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'evc-menu-item',
  standalone: true,
  imports: [SvgIconComponent, OptionalAsyncPipe, NgClass, AvatarComponent],
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Output() public readonly itemClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly organizationClicked: EventEmitter<BadgeItemType> = new EventEmitter<BadgeItemType>();

  @Input() public item?: MenuItemType;
  @Input() public badgeItem?: BadgeItemType;
  @Input() public aditionalText?: string;
  @Input() public selected = false;

  public initials: string | undefined;

  constructor(
    private _actionService: ActionService,
  ) {}

  public handleItemClick(item: MenuItemType | undefined): void {
    if (!item) {
      return;
    }

    this._actionService.handleAction(item);
    this.itemClicked.emit();
  }

  public handleOrganizationClick(item: BadgeItemType): void {
    this.organizationClicked.emit(item);
  }

  public isActionURL(item: MenuItemType): boolean {
    return this._actionService.isActionURL(item);
  }
}
