import type { Signal } from '@angular/core';
import { effect, Injectable, signal, untracked } from '@angular/core';

import type { ThemeType } from '../../types/theme.type';
import { AvailableThemes } from '../../types/theme.type';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _theme = signal<ThemeType>('auto');
  set theme(theme:ThemeType) {
    this._theme.set(theme);
  }
  get theme(): Signal<ThemeType> {
    return this._theme.asReadonly();
  }

  constructor() {
    effect(() => {
      const theme = this._theme();
      untracked(() => this.applyTheme(theme));
    });
  }

  public applyTheme(theme: ThemeType): void {
    const htmlElement: HTMLElement | null = document.querySelector('html');
    if (htmlElement) {
      htmlElement.classList.remove(
        ...AvailableThemes.map(possibleTheme => `${possibleTheme}-theme`),
      );
      htmlElement.classList.add(`${theme}-theme`);
    }
  }
}
