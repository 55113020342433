/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { firstValueFrom, map, switchMap } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import type { ApiSaveUserPayload, UserApi, UserGreenfieldApi, UserProfile } from '../../core-client/user/user.type';
import { PlatformConfigService } from '../../services/config/config.service';
import type { ApiResponse } from '../../types/api.type';
import { AuthService } from '../auth/auth.service';
import { CoreClientUtilsService } from '../utils/core-client-utils.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #destroyRef = inject(DestroyRef);
  #coreClientUtilsService = inject(CoreClientUtilsService);
  #profile:WritableSignal<Maybe<UserProfile>> = signal(undefined);
  #ready = signal(false);
  #authService = inject(AuthService);
  #httpClient = inject(HttpClient);
  private _configService = inject(PlatformConfigService);

  // ready after fetching orgs
  get ready(): Signal<boolean> {
    return this.#ready.asReadonly();
  }
  get profile(): Signal<Maybe<UserProfile>> {
    return this.#profile.asReadonly();
  }
  get isAvatarImage(): boolean {
    return this.profile()?.avatar?.type === 'image';
  }

  setUser(user: UserApi|undefined):void {
    const profile = user ? this.#coreClientUtilsService.computeUserProfile(user) : undefined;
    this.#profile.set(profile);
  }

  /** fetch user profile
   * * expected to call this when aquire token (cf platform/app.component) */
  async init(): Promise<Maybe<UserProfile>> {
    await this.updateProfile();
    this.#ready.set(true);

    return this.profile();
  }

  /** fetch user info
   * - remap data to fit our app model
   * - then update our profile
   */
  async updateProfile(): Promise<UserProfile> {
    return firstValueFrom(this.fetchUser$())
    .then(userProfile => {
      this.setUser(userProfile);

      return this.profile()!;
    })
    .catch(error => {
      this.setUser(undefined);

      throw error;
    });
  }

  saveUser(payload:ApiSaveUserPayload): Promise<UserProfile> {
    return firstValueFrom(this.#saveUser$(payload))
    .then(() => {
      const updatedProfile = {
        ...this.profile(),
        ...payload,
      };

      this.setUser(updatedProfile);

      return this.profile() as UserProfile;
    });
  }

  fetchUser$() :Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._configService.get('api')!;
    const apiUri = `${uri}${endpoints.user}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiResponse<UserGreenfieldApi>>(apiUri)),
      map(({ result }) => result),
    );
  }

  openProfile():void {
    const uri = this._configService.get('uris').user;
    if (!uri) return;
    window.open(uri, '_blank');
  }

  #saveUser$(payload:ApiSaveUserPayload): Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._configService.get('api')!;
    const apiUri = `${uri}${endpoints.user}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.put<ApiResponse<UserGreenfieldApi>>(apiUri, payload)),
      map(({ result }) => result),
    );
  }
}
