import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { PlatformConfigService } from '../../services/config/config.service';
/**
 * Force redirect to logout url after MSAL failure
 *
 * @see : [MSAL lib does not handle apps with every routes protected by MSALGuard](
 *  https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4614#issuecomment-1075668401
 * )
 *
 * This guard is a workaround to handle the case where the user is not authenticated after redirect ;
 * and so force a redirect to external public URI.
 * - use config : `auth.redirects.fail | uris.logout | 'https://evidentscientific.com'`
 *
 * @see AUTH_FAIL_URI env variable to match route path with this guard
 */
export const AuthFailureGuard: CanActivateFn = (
): Observable<boolean> => {
  const platformConfigService = inject(PlatformConfigService);

  const redirectUrl = platformConfigService.get('auth')?.redirects.fail
    ?? platformConfigService.get('uris')?.logout
    ?? 'https://evidentscientific.com';

  window.location.href = redirectUrl;

  return of(false);
};
