import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'evc-gradient-border-mask',
  standalone: true,
  imports: [NgClass],
  templateUrl: './gradient-border-mask.component.html',
  styleUrls: ['./gradient-border-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradientBorderMaskComponent {
  @Input() public type: 'topbar' | 'menu' = 'topbar';
}
