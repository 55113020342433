import type { AppEnv } from './environment.default';
import { ENVIRONMENT, environment as defaultEnv } from './environment.default';

export const environment: AppEnv = {
  ...defaultEnv,
  ENV: ENVIRONMENT.development,
  AUTH_CLIENT_ID: '7aef26ec-90c1-4ca8-bfbf-65b415556693',
  DEBUG_AUTH_FLOW: true,
  DEBUG_LOADING_FLOW: true,
};
