import type {
  AfterViewInit,
  ComponentRef,
  OnChanges,
  OnDestroy,
  SimpleChanges } from '@angular/core';
import {
  ApplicationRef,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { computePosition, flip, offset, Placement, shift } from '@floating-ui/dom';
import { type Observable, Subject, takeUntil, timer } from 'rxjs';

import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { TooltipVisibility } from '../../types/tooltip-visibility.type';

@Directive({
  selector: '[evcTooltipHover]',
  standalone: true,
})
export class TooltipHoverDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input() public evcTooltipHover: string | Observable<string> = '';
  @Input() public evcTooltipOffset = 4;
  @Input() public evcTooltipDelay = 0;
  @Input() public evcTooltipPlacement: Placement = 'right';
  @Input() public evcTooltipVisible: TooltipVisibility = 'auto';

  private componentRef: ComponentRef<TooltipComponent> | null = null;
  private scrollParent: HTMLElement | null = null;
  private scrollListener?: () => void;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly _el: ElementRef<HTMLElement>,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appRef: ApplicationRef,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly _ngZone: NgZone,
  ) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    timer(this.evcTooltipDelay)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showTooltip();
      });
  }

  @HostListener('mouseleave')
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.evcTooltipVisible && !changes.evcTooltipVisible.firstChange) {
      this.setVisibility();
    }
  }

  public ngAfterViewInit(): void {
    this.setVisibility();
  }

  private setVisibility(): void {
    if (this.evcTooltipVisible !== 'auto') {
      return;
    }

    if (this._el.nativeElement.offsetWidth < this._el.nativeElement.scrollWidth) {
      this.evcTooltipVisible = 'visible';

      return;
    }

    const children: HTMLElement[] = Array.from(this._el.nativeElement.children) as HTMLElement[];
    if (children.some(child => child.offsetWidth < child.scrollWidth)) {
      this.evcTooltipVisible = 'visible';

      return;
    }

    this.evcTooltipVisible = 'hidden';
  }

  private showTooltip(): void {
    if (this.evcTooltipVisible !== 'visible' || this.componentRef !== null) {
      return;
    }

    this.componentRef = this._viewContainerRef.createComponent(TooltipComponent);

    if (this.scrollParent === null) {
      this.scrollParent = this.getScrollParent(this._el.nativeElement);
    }

    this._ngZone.runOutsideAngular(() => {
      // Run outside Angular to avoid triggering change detection on every scroll event
      if (this.scrollParent) {
        this.scrollListener = this._renderer.listen(this.scrollParent, 'scroll', () => {
          this._ngZone.run(() => this.setTooltipComponentProperties());
        });
      }
    });

    this.setTooltipComponentProperties();
  }

  private getScrollParent(element: HTMLElement): HTMLElement | null {
    let parent: HTMLElement | null = element.parentElement;

    while (parent !== null) {
      const style: CSSStyleDeclaration = window.getComputedStyle(parent);
      const overflowY: string = style.overflowY;

      if (parent.scrollHeight > parent.clientHeight && overflowY !== 'hidden') {
        return parent;
      }

      parent = parent.parentElement;
    }

    return window.document.documentElement;
  }

  private setTooltipComponentProperties(): void {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.instance.tooltip = this.evcTooltipHover;
    this._changeDetectorRef.detectChanges();

    let placement: Placement = this.evcTooltipPlacement;

    if (this.scrollParent !== null) {
      const elementRect: DOMRect = this._el.nativeElement.getBoundingClientRect();
      const parentRect: DOMRect = this.scrollParent?.getBoundingClientRect();

      if (elementRect.top < parentRect.top) {
        placement = 'bottom';
      } else if (elementRect.bottom > parentRect.bottom) {
        placement = 'top';
      }
    }

    computePosition(this._el.nativeElement, this.componentRef?.location.nativeElement, {
      placement,
      middleware: [flip(), shift(), offset(this.evcTooltipOffset)],
    }).then(({ x, y }) => {
      if (this.componentRef !== null) {
        this.componentRef.instance.left = x;
        this.componentRef.instance.top = y;
        this.componentRef.changeDetectorRef.detectChanges();
      }
    });
  }

  private destroy(): void {
    if (this.componentRef !== null) {
      this._appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }

    if (this.scrollListener) {
      this.scrollListener();
    }
  }
}
