import { CommonModule } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import type { Observable } from 'rxjs';

import { NavButtonComponent, SvgIconComponent } from '@evc/web-components';

import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'evc-search',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, NavButtonComponent, FormsModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  constructor(private readonly _searchService: SearchService) {}

  public searchText$?: Observable<string>;

  public ngOnInit(): void {
    this.searchText$ = this._searchService.searchText$;
  }

  public searchMe(event: KeyboardEvent): void {
    this._searchService.searchMe(event);
  }

  public clearSearch(): void {
    this._searchService.clearSearch();
  }

  public onSearchTextChanged(value: string): void {
    this._searchService.setSearchText(value);
  }
}
