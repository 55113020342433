<div class="search">
  <input
    type="text"
    autocomplete="off"
    class="search__input"
    placeholder="Search"
    [ngModel]="searchText$ | async" (ngModelChange)="onSearchTextChanged($event)"
    (keyup)="searchMe($event)"
  />

  @if(searchText$ | async) {
    <evc-button type="icon" (click)="clearSearch()" variant="round">
      <evc-svg-icon size="lg" icon="xmark"></evc-svg-icon>
    </evc-button>
  }
</div>
