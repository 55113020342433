<!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus  -->
<div #modalBackdrop class="modal__backdrop" (click)="onCloseModal()"></div>

<div class="modal__wrapper">
  <div
    #modalContainer
    class="modal__container"
    [style.width]="width"
    [style.height]="height"
    [style.minWidth]="minWidth"
    [style.minHeight]="minHeight"
    [style.maxWidth]="maxWidth"
    [style.maxHeight]="maxHeight"
  >
    <div class="modal__header">
      <div class="modal__title">
        <ng-content select="[modal-title]"></ng-content>
      </div>
      <div class="modal__controls">
        @if(showReduceButton){
          <evc-button
            type="icon"
            size="sm"
            variant="semi"
            (click)="reduceModal.emit()"
          >
            <evc-svg-icon size="md" icon="hyphen"></evc-svg-icon>
          </evc-button>
        }
        
        @if(showCloseButton){
          <evc-button
            type="icon"
            size="sm"
            variant="semi"
            (click)="closeModal.emit()"
          >
            <evc-svg-icon size="md" icon="xmark"></evc-svg-icon>
          </evc-button>
        }
      </div>
    </div>

    <div class="modal__content">
      <ng-content select="[modal-content]"></ng-content>
    </div>

    <div class="modal__actions">
      <ng-content select="[modal-actions]"></ng-content>
    </div>
  </div>
</div>
