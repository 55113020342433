import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import type { SearchFn } from '../../services/search/search.type';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchTextSubject = new BehaviorSubject<string>('');

  public searchText$: Observable<string> = this.searchTextSubject.asObservable().pipe(distinctUntilChanged());
  public search?:SearchFn;

  public searchMe(event: KeyboardEvent): void {
    if (event.code === 'Enter' && this.search) {
      this.search(this.searchTextSubject.getValue());
    }
  }

  public clearSearch(): void {
    if (this.search) {
      this.searchTextSubject.next('');
      this.search('');
    }
  }

  public setSearch(search: SearchFn): void {
    this.search = search;
  }

  public getSearch(): SearchFn| undefined {
    return this.search;
  }

  public setSearchText(value: string): void {
    this.searchTextSubject.next(value);
  }

  public getSearchText(): string {
    return this.searchTextSubject.getValue();
  }
}
