<div class="backdrop" (click)="closePanel()"></div>

<div class="right-panel">
  <div class="right-panel__top-content">
    <ng-content select="[top-content]"></ng-content>
    <evc-button
      type="icon"
      variant="semi"
      size="sm"
      (click)="closePanel()"
    >
      <evc-svg-icon icon="xmark" size="sm"></evc-svg-icon>
    </evc-button>
  </div>
  <div class="right-panel__content">
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="right-panel__bottom-content">
    <ng-content select="[bottom-content]"></ng-content>
  </div>
</div>
