<div class="user-card">
  <evc-avatar
    class="user-card__avatar"
    [avatar]="user.avatar"
    size="lg"
  ></evc-avatar>

  <div class="user-card__vertical-infos">
    <span
      class="user-card__big-title"
      evcTooltipHover="{{user.email | optionalAsync}}"
      evcTooltipVisible="auto"
      evcTooltipPlacement="bottom"
    >
      {{user.displayName}}
    </span>
    <evc-tag leftIcon="user" [text]="this.user.roles[0]"></evc-tag>
  </div>
</div>
