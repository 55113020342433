/** App config
 * - define idp endpoints, tenants name etc for each environment
 * - then compute MSAL config from it
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { provideEnv, getAuthProviders } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideEnv(environment),
 *     ...getAuthProviders(environment),
 *   ],
 * };
 * ```
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import type { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type {
  MsalGuardAuthRequest,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import type { IPublicClientApplication } from '@azure/msal-browser';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import type { Maybe, ValueOf } from '@evc/web-components';
import { ENV_VARIABLES, objectMap } from '@evc/web-components';

import type { PlatformEnv } from '../../providers/env.type';
import { getUrisConfig } from '../../services/config/config.default';
import type { ApplicationURIs, AuthAuthorities, AuthConfig, AuthPolicies, AuthRedirects } from '../../services/config/config.type';

/* way to inject config from a callback to pass .env config : see getAuthProviders()*/
export const AUTH_CONFIG = new InjectionToken<string>('AUTH_CONFIG');

export const STORAGE_KEYS = {
  toid: 'tnmconnect-redirect-toid',
  loginHint: 'tnmconnect-redirect-login-hint',
};

/** generate needed providers for MSAL - to be imported in app.config.ts */
export function getAuthProviders(environment?:PlatformEnv&Record<string, unknown>):Provider[] {
  if (!environment?.GREENFIELD) return [];

  return [
    {
      provide: AUTH_CONFIG,
      useFactory: getAuthConfig,
      deps: [ENV_VARIABLES],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor, // you can set your own @see exemples/_auth.intercepror.ts,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}

/** compute clean config for auth based on some .env config
 * this one is special as it is use by authProviders
 * important to note that environment will not be parsed and defaulted before this
 */
export function getAuthConfig(environment:PlatformEnv):Maybe<AuthConfig> {
  const { GREENFIELD, ENV, AUTH_CLIENT_ID, AUTH_FORCE_ORGANIZATION } = environment ?? {};
  if (!GREENFIELD) return undefined;

  const env = ENV;
  const clientId = AUTH_CLIENT_ID;
  const forceOrganization = AUTH_FORCE_ORGANIZATION || false;
  const tenant = (() => ({
    local: 'evcpltidpdev',
    development: 'evcpltidpdev',
    testing: 'evcpltidpdev',
    staging: 'evcpltidpstg2',
    production: 'evidentconnect',
  }[env]))();

  if (!clientId || !tenant) return undefined;

  const policies:AuthPolicies = {
    signUpSignIn: 'b2c_1a_signup_signin',
    resetPassword: 'b2c_1a_passwordreset',
    editProfile: 'b2c_1a_profileedit',
  };

  const uris:ApplicationURIs = getUrisConfig(environment);

  const redirects:AuthRedirects = {
    success: uris.root!,
    fail: uris.logout ?? uris.public!,
    organization: uris.organization!,
  };

  const AUTHORITY_DOMAIN = `${tenant}.b2clogin.com`;
  const AUTHORITY_URI = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/`/* + policyName */;

  return {
    clientId,
    tenant,
    policies,
    redirects,
    forceOrganization,
    scopes: [clientId],
    b2cPolicies: {
      names: policies,
      authorities: objectMap(policies, (policy: ValueOf<AuthAuthorities>) => `${AUTHORITY_URI}${policy}`),
      authorityDomain: AUTHORITY_DOMAIN,
    },
  };
}

function MSALInstanceFactory(environment:PlatformEnv, config:AuthConfig): IPublicClientApplication {
  const { ENV } = environment ?? {};

  const { clientId, redirects, b2cPolicies } = config ?? {};

  const logLevel = ENV === 'production'
    ? LogLevel.Error
    : (ENV === 'staging' ? LogLevel.Warning : LogLevel.Verbose);

  return new PublicClientApplication({
    auth: {
      authority: b2cPolicies.authorities.signUpSignIn as string,
      knownAuthorities: [b2cPolicies.authorityDomain],
      clientId,
      redirectUri: redirects.success,
      postLogoutRedirectUri: redirects.fail,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowRedirectInIframe: true,
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel,
        piiLoggingEnabled: false,
        // // KEEP IN NEED - you may implement your logger logic here
        // loggerCallback:(_logLevel: LogLevel, message: string)=>{
        //   if (env === 'production') return;
        //   console.log('[msal]', message); // eslint-disable-line no-console
        // }
      },
    },
  });
}

function MSALInterceptorConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalInterceptorConfiguration {
  const { API_URI } = environment ?? {};

  const { scopes } = config ?? {};
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(API_URI!, scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalGuardConfiguration {
  const { LOCALES_STORAGE_KEY } = environment ?? {};

  const { scopes, b2cPolicies } = config ?? {};
  const { AUTH_FAIL_ROUTE } = environment;

  const extraQueryParameters:Record<string, string> = {};

  const authRequest:MsalGuardAuthRequest & { extraQueryParameters:Record<string, string> } = {
    authority: b2cPolicies.authorities.signUpSignIn as string,
    scopes: [...scopes],
    extraQueryParameters,
  };

  const loginHint = localStorage.getItem(STORAGE_KEYS.loginHint);
  if (loginHint) {
    localStorage.removeItem(STORAGE_KEYS.loginHint);
    authRequest.loginHint = loginHint;
  }

  const toid = localStorage.getItem(STORAGE_KEYS.toid);
  if (toid) {
    localStorage.removeItem(STORAGE_KEYS.toid);
    authRequest.extraQueryParameters.toid = toid;
  }

  const configuration:MsalGuardConfiguration & { authRequest:MsalGuardAuthRequest } = {
    interactionType: InteractionType.Redirect,
    authRequest,
    loginFailedRoute: AUTH_FAIL_ROUTE ?? 'auth-fail',
  };

  const { AUTH_CAPTCHA_BYPASS_TOKEN } = environment;
  if (AUTH_CAPTCHA_BYPASS_TOKEN && AUTH_CAPTCHA_BYPASS_TOKEN !== '{IAC_AADB2C_CAPTCHA_BYPASS_TOKEN}') {
    configuration.authRequest!.extraQueryParameters!.cbt = AUTH_CAPTCHA_BYPASS_TOKEN;
  }

  const lang = getLanguage(LOCALES_STORAGE_KEY);
  if (lang) {
    configuration.authRequest!.extraQueryParameters!.ui_locales = lang;
  }

  return configuration;
}

function getLanguage(storageKey?:string): string|null {
  return getLangFromUrl()
    || getLangFromStorage(storageKey)
    || getLangFromBrowser();
}

function getLangFromUrl(): string|null {
  return new URL(window.location.href).searchParams.get('lang');
}

function getLangFromStorage(storageKey='evc_user_lang'): string|null {
  return localStorage.getItem(storageKey);
}

function getLangFromBrowser(): string|null {
  const locale = navigator.languages
    ? navigator.languages[0]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    : (navigator.language || (navigator as any).userLanguage as string);

  return locale?.split('-')[0];
}

export default getAuthProviders;
