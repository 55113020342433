import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FrameComponent } from '@evc/web-components';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';

@Component({
  standalone: true,
  selector: 'adm-app-licenses',
  templateUrl: 'app-licenses.component.html',
  styleUrls: ['./app-licenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FrameComponent,
    TranslatePipe,
  ],
})
export class PageAppLicensesComponent {

}
