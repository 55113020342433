import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserProfile } from '@evc/platform';
import { AvatarComponent, OptionalAsyncPipe, TagComponent, TooltipHoverDirective } from '@evc/web-components';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'adm-user-card',
  standalone: true,
  imports: [OptionalAsyncPipe, TooltipHoverDirective, AvatarComponent, TagComponent],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss',
})
export class UserCardComponent {
  @Input() user!: UserProfile;
}
