<table mat-table [dataSource]="dataSource()" class="mat-elevation-z8" matSort>

  @for (column of displayedColumns; track $index) {
    <ng-container matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [sortActionDescription]="'sort-{{column}}'">
        <div class="evc-table__col-header">
          {{ column }}
        </div>
      </th>

      <td mat-cell *matCellDef="let element"
        [ngClass]="{'evc-table--clickable-cell' : element[columnMapping[column]].click ?? false}"
        (click)="onCellClick(element[columnMapping[column]])"
      >
        @if (element[columnMapping[column]]; as cellElement) {
          <div>
            <evc-cell [cellData]="cellElement"></evc-cell>
          </div>
        }
      </td>
    </ng-container>
  }

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="{'evc-table--clickable-row' : clickableRows}"
    (click)="onRowClick(row)"
  ></tr>
</table>