import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { WebComponentsConfigService } from '../../services/config/config.service';
import { IconListType } from '../../types/icon-list.type';

export type IconType = {
  icon?: IconListType;
  svgPath?: string;
};

@Component({
  selector: 'evc-svg-icon',
  standalone: true,
  imports: [],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() public icon?: IconListType;
  @Input() public svgPath?: string;
  @Input() public size: 'logo' | 'lg' | 'md' | 'sm' | 'xs' | 'free' = 'free';

  /** you may define it via the config service. @see storybook Settings/Config */
  @Input() public basePath = this.config.getComputed('ASSETS_BASE_PATH');

  constructor(
    private readonly config: WebComponentsConfigService,
  ) {}
}
