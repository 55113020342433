/** Hack to be able to export TranslatePipe from this service
 * I want this tso will be able to swap ngx-translate in future
 * and today we do not have a standalone pipe so need to use the ngx-translate module
 *
 * => this is just to fix deprecated lib issues
 */
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';

export { LangChangeEvent, TranslateService, TranslateLoader } from '@ngx-translate/core';
export { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  exports: [TranslateModule],
})
export class TranslatePipe {
  static forRoot(config: TranslateModuleConfig): ModuleWithProviders<TranslateModule> {
    return TranslateModule.forRoot(config);
  }
}
