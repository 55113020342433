import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { AvatarComponent, StatusIndicatorComponent, SvgIconComponent, TagComponent } from '@evc/web-components';

import { TableCell } from '../table.type';

@Component({
  standalone: true,
  selector: 'evc-cell',
  templateUrl: 'cell.component.html',
  styleUrls: ['cell.component.scss'],
  imports: [NgClass, MatTableModule, SvgIconComponent, StatusIndicatorComponent, TagComponent, AvatarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent {
  @Input() cellData: TableCell = {
    value: { leadText: '' },
    disabled: false,
    iconLeft: { svgPath: '' },
  };

  cellDataKeys = ['leadText', 'mainText', 'subText'] as const;
}
