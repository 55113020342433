import { NgClass } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, ViewChild, WritableSignal } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { CellComponent } from './cell/cell.component';
import type { TableCell, TableRow, TableRowArray } from './table.type';

@Component({
  standalone: true,
  selector: 'evc-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  imports: [NgClass, MatTableModule, MatSortModule, CellComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  @Output() readonly rowClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();

  @Input() displayedColumns: string[] = [];
  @Input() columnMapping: { [key: string ]: string } = {};
  @Input() data!: WritableSignal<TableRowArray>;
  @Input() clickableRows = false;
  @Input() sortingDataAccessor!: (item: TableRow, property: string) => string;

  dataSource = computed(() => new MatTableDataSource(this.data()));

  ngAfterViewInit(): void {
    this.dataSource = computed(() => new MatTableDataSource(this.data()));
    this.dataSource().sort = this.sort;
    this.dataSource().sortingDataAccessor = this.sortingDataAccessor;
  }

  onRowClick(row: TableRow): void {
    this.rowClicked.emit(row);
  }

  onCellClick(cell: TableCell): void {
    if (cell.click) {
      cell.click();
    }
  }
}
