import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { MenuItemType } from '@evc/web-components';
import {
  ActionService,
  HorizontalWheelScrollDirective,
  IsScrollableDirective,
  OptionalAsyncPipe,
  SvgIconComponent,
} from '@evc/web-components';

import { PlatformConfigService } from '../../services/config/config.service';
import { SearchService } from '../../services/search/search.service';
import { AppIdentity } from '../../types/app-identity.type';

@Component({
  selector: 'evc-header',
  standalone: true,
  imports: [
    NgClass,
    SvgIconComponent,
    HorizontalWheelScrollDirective,
    IsScrollableDirective,
    OptionalAsyncPipe,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() public topTabs?: MenuItemType[];
  @Input() public appIdentity?: AppIdentity;

  constructor(
    private readonly _actionService: ActionService,
    private readonly _searchService: SearchService,
    private readonly platformConfigService: PlatformConfigService,
  ) {}

  public handleTabClick(tab: MenuItemType): void {
    this._searchService.clearSearch();
    this.setSelectedTab(tab);
    this._actionService.handleAction(tab);
  }

  public handleTitleClick(): void {
    this._searchService.clearSearch();
    this.appIdentity?.onTitleClick?.();

    this.topTabs?.forEach(tab => {
      tab.selected = false;
    });
  }

  public trackByKey(index: number, item: MenuItemType): string {
    return `${item.key}-${index}`;
  }

  public isTabDisabled(tabKey: string): boolean {
    const currentTab: MenuItemType | undefined = this.topTabs?.find(item => item.key === tabKey);

    return !!currentTab?.isDisabled;
  }

  private setSelectedTab(clickedTab: MenuItemType): void {
    this.topTabs?.forEach(tab => {
      if (tab.selected) {
        tab.selected = false;
      }

      if (tab.key === clickedTab.key) {
        tab.selected = true;
      }
    });
  }
}
