/**
 * Auth Service
 * bridge between the MSAL service and the application
 *
 * Note that you can use the MSAL service directly in your components, but it is recommended to use a service to encapsulate the MSAL service.
 *
 * TODO : everithing is private here
 * - may add final subject to redispatch some events
 * - or directly bridge a User and never exposing this flows
 */
import type { Signal } from '@angular/core';
import { DestroyRef, Inject, inject, Injectable, Optional, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivationStart, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import type { AccountInfo, AuthenticationResult, EndSessionRequest, EventMessage, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import type { Observable } from 'rxjs';
import { catchError, filter, firstValueFrom, map, merge, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import { PlatformConfigService } from '../../services/config/config.service';
import type { AuthConfig } from '../../services/config/config.type';

let DEBUG_AUTH_FLOW = false; // always false in prod

// available actions requests (values in .env)
enum ACTIONS {
  SIGN_UP_SIGN_IN='signUpSignIn',
}

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  // TODO : investigate why our flows may not return tfp param in our idTokenClaim
  tfp?: string, // Trust framework policy
  acr?: string, // deprecated : Authentication context class reference
};
type IdTokenClaims = {organization_id:string} & Record<string, unknown>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getMsalService():MsalService {
    return this.authService;
  }
  #router = inject(Router);
  #destroyRef = inject(DestroyRef);

  #config!:AuthConfig;
  get config():AuthConfig {
    return this.#config;
  }
  get clientId(): string {
    return this.config?.clientId;
  }

  #connected = signal(false);
  get connected():Signal<boolean> {
    return this.#connected.asReadonly();
  }

  #accessToken = signal<string|undefined>(undefined);
  get accessToken():Signal<string|undefined> {
    return this.#accessToken.asReadonly();
  }
  accessToken$ = toObservable(this.#accessToken).pipe(
    filter((token?:string) => !!token),
    takeUntilDestroyed(this.#destroyRef),
    tap((token) => {
      // eslint-disable-next-line no-console
      if (DEBUG_AUTH_FLOW) console.log('[auth] accessToken', token);
    }),
  );

  #loginHint = signal<Maybe<string>>(undefined);
  get loginHint(): Signal<Maybe<string>> {
    return this.#loginHint.asReadonly();
  }
  set loginHint(loginHint:Maybe<string>) {
    this.#loginHint.set(loginHint);
  }

  // You may store state before a login request and retrieve it after the redirect
  #state = signal<string|undefined>(undefined);
  get state():Signal<string|undefined> {
    return this.#state.asReadonly();
  }

  #initFlowSubject = new ReplaySubject<boolean>(1);
  get initFlowSubject():Observable<boolean> {
    return this.#initFlowSubject.asObservable();
  }

  #isLogoutRouteSubject = new ReplaySubject<boolean>(1);

  /* complete after check for redirect org */
  #complete = signal(false);
  onComplete$ = toObservable(this.#complete)
      .pipe(
        filter((complete) => complete),
        takeUntilDestroyed(this.#destroyRef),
      );

  #idTokenClaims = signal<IdTokenClaims|undefined>(undefined);
  get idTokenClaims():Signal<IdTokenClaims|undefined> {
    return this.#idTokenClaims.asReadonly();
  }

  #language = signal<string|undefined>(undefined);
  set language(lang:string) {
    this.#language.set(lang);
  }
  #waitLang$:Observable<string> = toObservable(this.#language).pipe(
    takeUntilDestroyed(this.#destroyRef),
    filter((lang) => lang !== undefined && lang !== ''),
    map((lang?:string) => lang!),
  );

  constructor(
    @Optional() @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Optional() private authService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,
    private platformConfigService: PlatformConfigService,
  ) {
    if (!platformConfigService.greenfield) return;

    this.#config = platformConfigService.get('auth')!;

    DEBUG_AUTH_FLOW = platformConfigService.get('DEBUG_AUTH_FLOW') ?? false;
    if (!(['local', 'development']).includes(platformConfigService.get('env'))) DEBUG_AUTH_FLOW = false;
  }

  /** Start our init flow
   * - skiped if not greenfield
   * @returns Promise<boolean> - if connected with valid token
   *
   * * do not forget to call this on app init !
   **/
  async init(): Promise<boolean> {
    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.debug('[auth] init', this.#config, this);

    if (!this.#config) return false;

    // important because standalone
    this.authService.instance.enableAccountStorageEvents();

    // logout routes = special behaviour : do not attempt fetch any tokens
    this.#checkLogoutRoute$().subscribe((isLogoutRoute) => {
      this.#isLogoutRouteSubject.next(isLogoutRoute);
    });

    const flow = this.authService.handleRedirectObservable()
    .pipe(
      switchMap(() => this.#checkConnection$()),
      switchMap(() => this.#checkValidToken$()),
      switchMap(() => of(true)),
      catchError(() => of(false)),
      takeUntilDestroyed(this.#destroyRef),
      tap((isAuthenticated) => this.#initFlowSubject.next(isAuthenticated)),
      tap((isAuthenticated) => {
        // eslint-disable-next-line no-console
        if (DEBUG_AUTH_FLOW) console.log('[auth] init - flow complete', isAuthenticated);
      }),
    );

    // flows to handle AADB2C flows redirects
    this.#inProgress$().subscribe(this.#onInProgress.bind(this));
    this.#redirectSuccess$().subscribe(this.#onRedirectSuccess.bind(this));
    this.#redirectFail$().subscribe(this.#onRedirectFail.bind(this));

    return firstValueFrom(flow);
  }

  login(userFlowRequest?: Partial<RedirectRequest>/* | PopupRequest*/, extra?: Partial<RedirectRequest>):Observable<void> {
    const { redirects } = this.#config;
    const payload:RedirectRequest & {extraQueryParameters:Record<string, string>} = {
      scopes: [],
      extraQueryParameters: {},
      redirectUri: redirects.success,
      ...(this.msalGuardConfig?.authRequest ?? {}),
      ...(userFlowRequest??{}),
      ...extra ?? {},
    };

    const lang = this.#language();
    if (lang) {
      payload.extraQueryParameters = {
        ui_locales: lang,
        ...payload.extraQueryParameters,
      };
    }

    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.debug('[auth] login', payload);

    return this.authService.loginRedirect(payload);
  }

  /**
   * We force to pass an account so ADB can use Logout Front Channel to logout from any other apps (of same tenant)
   * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#logoutredirect
   */
  logout(logoutRequest?: EndSessionRequest):Observable<void> {
    const activeAccount = this.authService.instance.getActiveAccount();
    const homeAccount = activeAccount?.homeAccountId && this.authService.instance.getAccountByHomeId(activeAccount.homeAccountId);
    const account = homeAccount || activeAccount;

    logoutRequest = { account, ...logoutRequest };

    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.debug('[auth] logout', logoutRequest);

    return this.authService.logoutRedirect(logoutRequest);
  }

  /** request accesstoken routine
   * - first try to fetch localy + test if still valid for enough time
   * - if no try to use refreshToken
   * - if no use a login
   */
  requestAccessToken(scopes: string[] = [], extra?:Partial<SilentRequest>):Observable<
    void | AuthenticationResult | (AuthenticationResult & { account: AccountInfo; })
  > {
    const { b2cPolicies, redirects } = this.#config;
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.logout();

      throw new Error('requestAccessToken : No active account found !');
    }
    // need at least 1 scope or no cache : https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3526
    const scopesWithDefault = Array.from(new Set([...scopes, this.#config.clientId]));

    let request: SilentRequest = {
      authority: b2cPolicies.authorities[ACTIONS.SIGN_UP_SIGN_IN],
      scopes: scopesWithDefault,
      account,
      redirectUri: redirects.success,
      tokenBodyParameters: {},
      state: this.state(),
    };

    if (extra) {
      request = { ...request, ...extra };
    }

    const orgId = this.idTokenClaims()?.organization_id;
    if (orgId) request.tokenBodyParameters!.toid = orgId;

    const cbt = this.platformConfigService.get('AUTH_CAPTCHA_BYPASS_TOKEN');
    if (cbt && cbt !== '{IAC_AADB2C_CAPTCHA_BYPASS_TOKEN}') request.tokenBodyParameters!.cbt = cbt;

    return this.authService.acquireTokenSilent(request)
    .pipe(
      tap((response) => {
        // eslint-disable-next-line no-console
        if (DEBUG_AUTH_FLOW) console.log('[auth] requestAccessToken - silent', { response, request });
      }),
      // if silent token acquisition fails, fallback to interactive method (prefer popup here)
      catchError((error) => {
        // eslint-disable-next-line no-console
        if (DEBUG_AUTH_FLOW) console.log('[auth] requestAccessToken - retry redirect ?', error instanceof InteractionRequiredAuthError, { request, error });

        if (error instanceof InteractionRequiredAuthError) {
          return this.msalGuardConfig.interactionType === InteractionType.Popup
            ? this.authService.acquireTokenPopup(request)
            : this.authService.acquireTokenRedirect(request);
        }

        throw error;
      }),
    );
  }

  /** > ?redirect_uri to fail (public) route because need org */
  redirectToCreateOrganization(redirectUri?:string):void {
    const { fail, organization } = this.#config.redirects;

    const uri = `${organization}?lang=${this.#language()}&redirect_uri=${redirectUri ?? fail}`;

    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.log('[auth] redirectToCreateOrganization', { uri });

    window.location.href = uri;
  }

  /**
   * ! ---------------- FLOWS ----------------
   */

  /** On inProgress : fetch current login data
   * - after a successfull login, if you reload we may check if still loggued (localStorage)
   * ? - if ok, may request a new access-token to complete ?
   */
  #onInProgress() {
    this.#checkAndSetConnexion();
    this.#checkAndSetActiveAccount();

    const connected = this.#connected();
    const accessToken = this.#accessToken();
    const shouldRefreshToken = connected && !accessToken;

    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.debug('[auth] onInProgress', { shouldRefreshToken, connected, accessToken });

    if (!shouldRefreshToken) return;

    // may refresh token on 1st load - exept if logout route [cf auth-logout.guard]
    this.#isLogoutRouteSubject
    .pipe(
      filter((isLogoutRoute) => !isLogoutRoute),
      map(() => this.#config.clientId),
      switchMap((clientId) => this.requestAccessToken([clientId])),
    )
    .subscribe();
  }

  /** On redirect success - here have an updated token
   * #onRedirectSuccessFromSignupSignin will match login or aquireToken - and event register will pass through here
   *
   * * if no orgId within token claim => redirect org creation
   * * for now only support SIGN_UP_SIGN_IN policy
   */
  #onRedirectSuccess(result: EventMessage) {
    const payload = result.payload as AuthenticationResult;
    const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

    this.#idTokenClaims.set(idtoken);

    if (DEBUG_AUTH_FLOW) {
      // eslint-disable-next-line no-console -- debug flow
      console.debug('[auth] redirect success', result.eventType, { idtoken, result, account: payload.account });
    }

    const mustCreateOrganization = !idtoken.organization_id
      && this.#config.forceOrganization;

    if (mustCreateOrganization) {
      this.#waitLang$.subscribe(() => {
        this.redirectToCreateOrganization();
      });
    } else {
      this.#complete.set(true);
    }

    this.authService.instance.setActiveAccount(payload.account);
    this.#saveAccessToken(payload.accessToken);

    if (payload.state) this.#state.set(payload.state);

    return result;
  }

  /** On redirect fail
   * - either a new login or a simple fetch access token
   * - may force login (if `init(forceAuth=true)`)
   */
  #onRedirectFail(result: EventMessage) {
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    const IS_FORGOT_PASSWORD_ERROR = result.error && result.error.message.indexOf('AADB2C90118') > -1;
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/7115
    const IS_AQUIRE_TOKEN_SILENT_EXPIRED_SESSION_ERROR = result.error && result.error.message.indexOf('AADB2C90077') > -1;

    if (DEBUG_AUTH_FLOW) {
      // eslint-disable-next-line no-console -- debug flow
      console.debug('[auth] #onRedirectFail :', {
        IS_FORGOT_PASSWORD_ERROR,
        IS_AQUIRE_TOKEN_SILENT_EXPIRED_SESSION_ERROR,
      }, result);
    }

    return result;
  }

  /**
   * ! ---------------- PRIVATE ----------------
   */

  /** subtility : isConnected does not mean have access (expired token) / if not, cancel flow here */
  #checkAndSetConnexion(): void {
    const accounts = this.authService.instance.getAllAccounts();
    const isConnected = accounts.length > 0;
    this.#connected.set(isConnected);

    // eslint-disable-next-line no-console
    if (DEBUG_AUTH_FLOW) console.debug('[auth] checkIfConnected', { isConnected, accounts });
  }

  #checkAndSetActiveAccount():Maybe<AccountInfo> {
    const { clientId } = this.#config;
    const accounts = this.authService.instance.getAllAccounts();
    let activeAccount = this.authService.instance.getActiveAccount() ?? undefined;

    if (!activeAccount && accounts.length > 0) {
      const matchAccount = accounts.find((account) => account.idTokenClaims?.aud === clientId) ?? accounts[0];
      activeAccount = matchAccount;
      this.authService.instance.setActiveAccount(activeAccount);
    }

    // eslint-disable-next-line no-console -- debug flow
    if (DEBUG_AUTH_FLOW) console.debug('[auth] #checkAndSetActiveAccount :', activeAccount, { activeAccount, accounts });

    return activeAccount;
  }

  #saveAccessToken(accessToken?:string):void {
    if (accessToken === this.#accessToken()
      || accessToken === ''
    ) return;
    this.#accessToken.set(accessToken);
  }

  /**
   * ! ---------------- OBSERVABLES ----------------
   */

  #inProgress$(): Observable<InteractionStatus> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  #redirectSuccess$(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntilDestroyed(this.#destroyRef),
      );
  }

  #redirectFail$(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) =>
        msg.eventType === EventType.LOGIN_FAILURE
        || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      takeUntilDestroyed(this.#destroyRef),
    );
  }

  #checkLogoutRoute$():Observable<boolean> {
    return this.#router.events
    .pipe(
      filter((event) => event instanceof ActivationStart),
      map(event => event as ActivationStart),
      takeUntilDestroyed(this.#destroyRef),
      take(1),
      map(route => route?.snapshot.data?.logout ?? false),
    );
  }

  #checkConnection$():Observable<boolean> {
    return this.connected()
    ? of(true)
    : throwError(() => new Error('auth: not connected'));
  }

  #checkValidToken$():Observable<Maybe<string>> {
    return merge(
      this.accessToken$,
      this.#redirectFail$()
      .pipe(
        filter((msg: EventMessage) => msg.interactionType !== InteractionType.Silent),
        tap(() => {
          // eslint-disable-next-line no-console
          if (DEBUG_AUTH_FLOW) console.debug('[auth] checkValidToken$ failure : redirectFail');
        }),
        switchMap(() => throwError(() => new Error('auth: no token'))),
      ),
      this.#isLogoutRouteSubject
      .pipe(
        filter((isLogoutRoute) => isLogoutRoute),
        tap(() => {
          // eslint-disable-next-line no-console
          if (DEBUG_AUTH_FLOW) console.debug('[auth] checkValidToken$ failure : logout route');
        }),
        switchMap(() => throwError(() => new Error('auth: no token'))),
      ),
    );
  }
}
