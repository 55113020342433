import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import type { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { environment } from '@env';

import { getAuthProviders, provideEnv } from '@evc/platform';
import { provideI18n } from '@shared/services/i18n/i18n.provider';

import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideEnv(environment),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(appRoutes),
    provideI18n(),
    provideAnimations(),
    ...getAuthProviders(environment),
  ],
};
