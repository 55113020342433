<div class="org-settings">
  <evc-frame>
    <div frame-title class="evc-app-title">
      <span class="evc-page-title">{{'PAGE_ORG_SETTINGS.title' | translate }}</span>
    </div>

    <div frame-content class="evc-page-container">
      <p>This is ORG SETTINGS PAGE</p>
    </div>
  </evc-frame>
</div>
