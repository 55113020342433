<div class="users">
  <evc-frame>
    <div frame-title class="evc-app-title">
      <span class="evc-page-title">{{'PAGE_TEAM.title' | translate }}</span>
    </div>

    <div frame-content class="evc-page-container">
      <div class="users__filters">
        <!-- TODO : to remake! -->
        <evc-input type="select" [options]="roleOptions" [value]="'owner'" class="users__role-filter" size="md"></evc-input>
        <evc-search class="users__search"></evc-search>
        <!-- TODO : end -->
      </div>


      @if (currentOrganization()) {
        <evc-table
          [displayedColumns]="appTableColumns()"
          [data]="tableData"
          [columnMapping]="columnMapping()"
          [sortingDataAccessor]="sortingDataAccessor"
          [clickableRows]="true"
          (rowClicked)="toggleRightPanel($event)"
        ></evc-table>
      }
    </div>
  </evc-frame>

  @if (isRightPanelOpen() && clickedUser(); as user) {
    <evc-right-panel (panelClosed)="toggleRightPanel()">
      <div top-content class="users__rp-top-infos">
        <adm-user-card [user]="user" class="users__rp-card"></adm-user-card>

        <div class="users__rp-quick-actions">
          <evc-button
            type="icon"
            variant="semi"
            size="sm"
            (click)="toggleUserActions()"
          >
            <evc-svg-icon icon="ellipsis" size="sm" class="vertical"></evc-svg-icon>
          </evc-button>
        </div>
      </div>

      @if (!isUserInOrganization() && isUserInvited()){
        <div content class="users__pending">
          <p class="users__disabled-text">
            <span>{{ 'PAGE_TEAM.right-panel.invitation-pending.title' | translate }}</span>
            <span>{{ 'PAGE_TEAM.right-panel.invitation-pending.message' | translate }}</span>
          </p>
          <evc-button
            type="tertiary"
            variant="semi"
            size="sm"
            (click)="resendInvitation()"
          >{{ 'PAGE_TEAM.right-panel.invitation-pending.cta' | translate }}</evc-button>
        </div>
      }
      @else {
        <div content class="users__details">
          <adm-user-details [user]="user"></adm-user-details>
        </div>
      }
    </evc-right-panel>
  }
</div>

@if (showUserActions()) {
  <evc-dropdown-menu
    evcCloseOnClickOutside
    class="users__user-actions-menu"
    [menuConfig]="userActionsMenu()"
    (clickedOutside)="toggleUserActions()"
    [showDividers]="true"
  >
  </evc-dropdown-menu>
}