import type {
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[evcIsScrollable]',
  standalone: true,
})
export class IsScrollableDirective implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.paddingLeft') public paddingLeft = '0px';
  @HostBinding('style.paddingRight') public paddingRight = '0px';
  @HostBinding('style.paddingTop') public paddingTop = '0px';
  @HostBinding('style.paddingBottom') public paddingBottom = '0px';

  @Input() public scrollPosition: 'left' | 'right' | 'top' | 'bottom' = 'right';

  public isScrollable?: boolean;

  private resizeSubscription?: Subscription;

  constructor(private _el: ElementRef) {}

  public ngOnInit(): void {
    const resizeObservable: Observable<Event> = fromEvent(window, 'resize').pipe(debounceTime(300));
    this.resizeSubscription = resizeObservable.subscribe(() => {
      this.checkScrollable();
    });
  }

  public ngAfterViewInit(): void {
    this.checkScrollable();
  }

  public ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  public checkScrollable(): void {
    const element: HTMLElement = this._el.nativeElement;
    this.isScrollable =
      element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    this.adjustPadding();
  }

  private adjustPadding(): void {
    if (this.isScrollable) {
      switch (this.scrollPosition) {
        case 'left':
          this.paddingLeft = '2px';
          break;
        case 'right':
          this.paddingRight = '2px';
          break;
        case 'top':
          this.paddingTop = '2px';
          break;
        case 'bottom':
          this.paddingBottom = '2px';
          break;
        default:
          break;
      }
    } else {
      this.paddingLeft = '0px';
      this.paddingRight = '0px';
      this.paddingTop = '0px';
      this.paddingBottom = '0px';
    }
  }
}
