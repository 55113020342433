import { AsyncPipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { ɵisPromise, ɵisSubscribable, Pipe } from '@angular/core';
import type { Observable, Subscribable } from 'rxjs';

@Pipe({
  name: 'optionalAsync',
  pure: false,
  standalone: true,
})
export class OptionalAsyncPipe extends AsyncPipe implements PipeTransform {
  public override transform(obj: null | undefined): null;
  public override transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T> | T): T | null;
  public override transform<T>(obj: never): T | null {
    return ɵisSubscribable(obj) || ɵisPromise(obj) ? super.transform<T>(obj) : obj ?? null;
  }
}
