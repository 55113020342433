import { Injectable } from '@angular/core';

import type { MenuItemType } from '../../types/menu-item.type';
import { BtnActionEnum } from '../../types/menu-item.type';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  public handleAction(item: MenuItemType): void {
    if (!item.action) return;

    if (item.action.type === BtnActionEnum.CALLBACK) {
      item.action.callbackOrUrl();
    } else {
      window.open(item.action.callbackOrUrl, '_blank');
    }
  }

  public isActionInteractive(item: MenuItemType): boolean {
    if (item.action) {
      return item.action.type === BtnActionEnum.CALLBACK;
    }

    return false;
  }

  public isActionURL(item: MenuItemType): boolean {
    if (item.action) {
      return item.action.type === BtnActionEnum.URL;
    }

    return false;
  }
}
