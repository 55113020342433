import { MOCK_API_ORG_PAYLOAD } from '../../core-client/organizations/organizations.mock';
import type { UserApi, UserConfig, UserGreenfieldApi, UserIMSApi, UserProfile } from '../../core-client/user/user.type';
import type { ApplicationType } from '../../types/user-menu.type';

export const MOCK_APPLICATIONS:ApplicationType[] = [
  {
    code: 'drive',
    hasWebApplication: true,
    launchUrl: 'https://drive.olympus-ims-dev.com/drive',
    launchIconUrl: 'https://drive.olympus-ims-dev.com/drive',
    name: 'Drive',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://drive.olympus-ims-dev.com/drive',
  },
  {
    code: 'device-manager',
    hasWebApplication: true,
    launchUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    launchIconUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
    name: 'Device Manager',
    supportedFileMimeTypes: [],
    userHasActiveValidLicense: true,
    openWithUrl: 'https://app.olympus-ims-dev.com/mydevices/devices',
  },
];

const MOCKED_USER_BASE = {
  id: 'MOCK_USER_ID',
  displayName: 'Display Name',
  givenName: 'firstname',
  surname: 'lastname',
  email: 'firstname.lastname@evidentscientific.com',
  phone: '+10123456789',
  address: undefined,
} as const;

export const MOCK_USER_CONFIG: UserConfig = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  roles: [{
    id: '0',
    name: 'admin',
    isReadOnly: false,
  }],
  color: '#badcff', // bg color > compute text light/dark
  // avatar?: string; // URI stay within user
  organizations: MOCK_API_ORG_PAYLOAD,
  applications: MOCK_APPLICATIONS,
};

export const MOCKED_USER_GREENFIELD_DATA: UserGreenfieldApi = {
  ...MOCKED_USER_BASE,
  address: { country: 'mock country', street: 'mock street', city: 'mock city', province: 'NA', postalCode: 'H0H 0H0' },
};

export const MOCKED_USER_IMS_DATA: UserIMSApi = {
  ...MOCKED_USER_BASE,
  role: 'admin',
  tenantId: 'MOCK_TENANT_ID',
};

export const MOCKED_USER_API_DATA: UserApi = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  roles: [{
    id: '0',
    name: 'admin',
    isReadOnly: false,
  }],
};

export const MOCK_USER_PROFILE: UserProfile = {
  ...MOCKED_USER_BASE,
  preferredLanguage: 'En',
  emailVerified: false,
  roles: ['admin'],
  avatar: {
    type: 'initials',
    color: '#badcff',
    light: true,
    initials: 'FL',
  },
};
