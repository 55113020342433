import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[evcHorizontalWheelScroll]',
  standalone: true,
})
export class HorizontalWheelScrollDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('wheel', ['$event'])
  public onWheel(event: WheelEvent): void {
    event.preventDefault();
    const factor = 30;
    this._el.nativeElement.scrollLeft += event.deltaY < 0 ? -factor : factor;
  }
}
