import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public currentShownModal: string | undefined;

  public closeCurrentModal(): void {
    this.currentShownModal = undefined;
  }

  public showCurrentModal(modal: string): void {
    this.currentShownModal = modal;
  }
}
