/**
 * Helper to inject environment variables in Angular
 * please inject this provider in the app.config.ts as we use this within our libs
 * used to pass .env from app to lib services using injectable
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { provideEnv } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideEnv(environment),
 *   ],
 * };
 * ```
 *
 * @example some.service.ts
 * ```ts
 * import type { EnvVariables } from '@evc/web-components';
 * import { ENV_VARIABLES } from '@evc/web-components';
 * export class SomeService {
 *   constructor(
 *     @Inject(ENV_VARIABLES) environment: WebComponentEnv,
 *   ) {
 *     console.log('ENV_VARIABLES', environment);
 *   }
 * }
 * ```
 */
import { InjectionToken } from '@angular/core';

import type { WebComponentsEnv } from './env.type';

/* way to inject config from a callback to pass .env config */
export const ENV_VARIABLES = new InjectionToken<string>('ENV_VARIABLES');

export type EnvVariables = Record<string, unknown>

export type EnvProvider = {
  provide: typeof ENV_VARIABLES;
  useFactory: () => EnvVariables
}

/** please use the one from `@evc/platform` lib if you are using the platform lib
 * - use this one only if using the web-components lib standalone
 */
export function provideEnv(environment:WebComponentsEnv):EnvProvider {
  return {
    provide: ENV_VARIABLES,
    useFactory: () => environment,
  };
}
