/**
 * WebComponents Config Service
 * ============================
 *
 * extends our baseConfig but focus only on WebComponentsConfig here
 * - typed config
 * - inject defaults for web-components
 * - inject env variables
 */

import { Inject, Injectable, Optional } from '@angular/core';

import { ENV_VARIABLES } from '../../providers/env.providers';
import { WebComponentsEnv } from '../../providers/env.type';
import { ConfigService } from './base-config.service';
import { defaultWebComponentsConfig } from './config.default';
import type { WebComponentsConfig } from './config.type';

@Injectable({
  providedIn: 'root',
})
export class WebComponentsConfigService extends ConfigService<WebComponentsConfig> {
  constructor(
    @Optional() @Inject(ENV_VARIABLES) private envVars: WebComponentsEnv,
  ) {
    super(defaultWebComponentsConfig, envVars);
  }
}
