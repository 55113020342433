import { inject, Injectable } from '@angular/core';

import type { Avatar, Maybe } from '@evc/web-components';

import { PlatformConfigService } from '../../services/config/config.service';
import type { Role, UserApi, UserApiPossibilities, UserIMSApi, UserProfile, UserRole } from '../user/user.type';

const DEFAULT_USER_COLOR = '#93C6F9';
const DEFAULT_USER_CHAR = '.';

@Injectable({
  providedIn: 'root',
})
export class CoreClientUtilsService {
  #configService = inject(PlatformConfigService);

  computeUserProfile(data: UserApi): UserProfile {
    const user = data as UserApiPossibilities;
    const id = user.id ?? user.tenantId;
    const fromDisplayName = (user.displayName && user.displayName !== 'unknown') && user.displayName;
    const fromFormalName = (user.givenName || user.surname) && [user.givenName, user.surname].join(' ');
    const displayName = fromDisplayName || fromFormalName
      || user.email?.split('@')[0]?.split(/[.,+-]/).join(' ') || id;
    const { email } = user;
    const roles = user.roles ?? (user as UserIMSApi).role;

    return {
      id,
      displayName,
      givenName: user.givenName ?? '',
      surname: user.surname ?? '',
      email,
      emailVerified: user.emailVerified ?? false,
      phone: user.phone ?? '',
      preferredLanguage: user.preferredLanguage,
      address: user.address,
      roles: this.computeUserRoles(roles),
      avatar: this.computeAvatar({ ...user, formalName: fromFormalName || displayName }),
    };
  }

  computeAvatar(user: UserApiPossibilities): Avatar {
    const { formalName, userImage, color } = user;
    if (userImage) {
      return {
        type: 'image',
        src: userImage,
      };
    }

    const initials = this.#computeUserInitials(formalName!);

    const colorDefaulted = color ?? DEFAULT_USER_COLOR;
    // TODO compute light/dark text
    const isLight = !!colorDefaulted;

    return {
      type: 'initials',
      color: colorDefaulted,
      light: isLight,
      initials,
    };
  }

  computeUserRoles(roles: Maybe<UserRole | UserRole[] | Role[]>): UserRole[] {
    if (!roles) return [];

    if (typeof(roles) === 'string') return [roles];

    if (roles.length < 1) return [];

    if (typeof (roles as UserRole[])[0] === 'string') {
      return roles as UserRole[];
    }

    return (roles as Role[]).map((role) => role.name);
  }

  #computeUserInitials(formalName: string): string {
    const words = formalName.split(' ') ?? [];

    const firstWord = (words.length > 0 && words[0]) || DEFAULT_USER_CHAR;
    const lastWord = words.length > 1 && words[words.length - 1];

    const firstWordLetterOne = (firstWord.length > 0 && firstWord[0].toLocaleUpperCase()) || DEFAULT_USER_CHAR;
    const firstWordLetterTwo = (firstWord.length > 1 && firstWord[1].toLocaleLowerCase()) || DEFAULT_USER_CHAR;
    const lastWordInitial = (lastWord && lastWord[0].toLocaleUpperCase()) || firstWordLetterTwo;

    return [
      firstWordLetterOne,
      lastWordInitial,
    ].join('');
  }
}
